@import "bulma/sass/utilities/initial-variables"

@import "./colors"

@import '~bulma-slider'
@import "bulma"

ul.legend
    list-style-type: none

div.color-box
    display: inline-block
    position: relative
    width: 15px
    height: 15px
    margin-right: 5px
    padding: 0
    bottom: -1px
    border-style: solid
    border-width: 2px
    border-color: #53565a

#tooltip
    z-index: 10
    position: fixed
    background-color: #89d9eb
    border-style: solid
    border-width: 2px
    border-color: #2c2a29
    padding: 7px

.auto-scroll
    overflow: auto

.filter-panel
    display: flex
    flex: 1 1 auto
    flex-direction: column
    max-height: 250px
    overflow-y: auto

.filter-panel-item
    padding: 2px

.activity-data
    min-height: 800px

span.filter-text > svg
    vertical-align: middle

.header-image-section
    height: 100px
    overflow: hidden
